import { SearchOutlineIcon, SortDownIcon, SortUpIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { Box, List, ListItem } from '@mui/material'
import { debounce, findKey } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { getShortcut, getStoredShortcuts } from '../helpers'
import { IEntitySidebarProps, IStyledBoxProps } from '../types'

const EntityHoverOrActiveSX = {
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  fontWeight: 500,
  borderRadius: '6px',
}

const Container = styled.div`
  border-right: 1px solid ${gray(4)};
  height: 95%;
  padding-inline: 10px;
  overflow: auto;
  box-sizing: border-box;
`

const StyledBox = ({ children, onClick }: IStyledBoxProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      px: 1,
      fontSize: 13,
      cursor: 'pointer',
    }}
    onClick={onClick}>
    {children}
  </Box>
)

export const EntitySidebar = ({ activeEntity, setEntity, entities }: IEntitySidebarProps) => {
  const [firstKey, setFirstKey] = useState('')
  const [search, setSearch] = useState<string>('')
  const [searchEnabled, setSearchEnabled] = useState<boolean>(false)
  const [sortBy, setSortBy] = useState<string>('asc')

  const activateEntity = (event: KeyboardEvent) => {
    const { key } = event

    setFirstKey(key === 'Shift' ? '' : key)

    const shortcuts = getStoredShortcuts()
    const foundEntity = findKey(shortcuts, (value) => value === firstKey + key)

    if (foundEntity) setEntity(foundEntity)
  }

  useEffect(() => {
    window.addEventListener('keydown', activateEntity)

    return () => window.removeEventListener('keydown', activateEntity)
  })

  const filteredEntities = useMemo(
    () =>
      entities
        ?.filter((entity) => entity?.toLowerCase().includes(search.toLowerCase()))
        ?.sort((a, b) => (sortBy === 'asc' ? a.localeCompare(b) : b.localeCompare(a))),
    [search, entities, sortBy]
  )

  const Icon = useMemo(() => (sortBy === 'asc' ? SortDownIcon : SortUpIcon), [sortBy])

  const handleBlur = useCallback(
    debounce(() => {
      setSearchEnabled(false)
      setSearch('')
    }, 300),
    []
  )

  return (
    <>
      <Box sx={{ position: 'sticky', px: 2, borderRight: '1px solid rgba(0, 0, 0, 0.08)' }}>
        <Text fontWeight='bold' fontSize={2} py={2}>
          Entities ({filteredEntities?.length})
        </Text>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
          {searchEnabled ? (
            <Input
              suffix='SearchOutlineIcon'
              value={search}
              backgroundColor='white'
              width='100%'
              placeholder='Search...'
              autoFocus
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onBlur={handleBlur}
            />
          ) : (
            <>
              <Text color='#363636' fontSize='12px'>
                Sort By
                <Box display='inline-block' px='8px'>
                  <span>{sortBy === 'asc' ? 'A - Z' : 'Z - A'}</span>
                  <Icon
                    className='h-3 w-3 cursor-pointer pl-2'
                    onClick={() => setSortBy(sortBy === 'asc' ? 'desc' : 'asc')}
                  />
                </Box>
              </Text>
              <SearchOutlineIcon className='h-4 w-4' onClick={() => setSearchEnabled(true)} />
            </>
          )}
        </Box>
      </Box>
      <Container>
        <List>
          {filteredEntities?.length === 0 ? (
            <span className='p-3 text-[#363636]'>No entity found</span>
          ) : (
            filteredEntities?.map((entity, index) => (
              <Box key={index}>
                <ListItem
                  sx={{
                    px: 0,
                    py: 1,
                    ...(activeEntity === entity ? EntityHoverOrActiveSX : {}),
                    '&:hover': EntityHoverOrActiveSX,
                  }}>
                  <StyledBox onClick={() => setEntity(entity)}>
                    <Text color='#363636'>{entity}</Text>
                    <Text color='gray'>{getShortcut(entity)}</Text>
                  </StyledBox>
                </ListItem>
              </Box>
            ))
          )}
        </List>
      </Container>
    </>
  )
}
