import { gray, styled } from '@invisible/ui/themes'
import InfoIcon from '@mui/icons-material/Info'
import { Box, LinearProgress, Typography } from '@mui/material'
import { groupBy } from 'lodash'

import { IAnnotationSidebarProps } from '../types'
import { AnnotationAccordion } from './AnnotationAccordion'

const Container = styled.div`
  border-left: 1px solid ${gray(4)};
  height: 95%;
  overflow: auto;
  box-sizing: border-box;
`

export const AnnotationSidebar = ({
  annotations,
  deleteAnnotation,
  isLoading,
}: IAnnotationSidebarProps) => {
  const groupedAnnotations = groupBy(annotations, 'entity')

  return (
    <>
      {isLoading ? <LinearProgress color='secondary' /> : null}
      <Box
        borderLeft='1px solid rgba(0, 0, 0, 0.08)'
        style={{
          position: 'sticky',
          padding: '10px 20px',
        }}>
        <Typography fontWeight='bold' fontSize={16}>
          Sub Strings ({annotations.length})
        </Typography>
      </Box>
      <Container>
        {annotations.length
          ? Object.keys(groupedAnnotations).map((entity) => (
              <AnnotationAccordion
                entity={entity}
                annotations={groupedAnnotations[entity]}
                deleteAnnotation={deleteAnnotation}
              />
            ))
          : !isLoading && (
              <Box sx={{ p: '10px' }}>
                <Box
                  sx={{ p: '10px', backgroundColor: 'rgba(0, 0, 0, 0.08)', borderRadius: '6px' }}
                  display='flex'
                  flexDirection='row'
                  columnGap={1}>
                  <InfoIcon fontSize='small' sx={{ color: 'gray' }} />
                  <Typography variant='body2'>
                    You don't have substrings created yet. Start adding your annotations and it will
                    be display here.
                  </Typography>
                </Box>
              </Box>
            )}
      </Container>
    </>
  )
}
