import {
  fromGlobalId,
  IBaseFilterConfigsQuery,
  IExportConfigurationDataQuery,
} from '@invisible/concorde/gql-client'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { capitalize } from 'lodash/fp'
import { Dispatch, useMemo } from 'react'
import { useGate } from 'statsig-react'

import { BaseVariableFilters } from './BaseVariableFilters'
import { DateFilter } from './DateFilter'
import { ExcludeBaseRunsByExportStatus } from './filters/ExcludeBaseRunsByExportStatus'
import { TReducerAction, TReducerState } from './reducer'
import { StepRunFilters } from './StepRunFilters'

interface IProps {
  state: TReducerState
  dispatch: Dispatch<TReducerAction>
  processData: IExportConfigurationDataQuery | undefined
  filterConfigs: IBaseFilterConfigsQuery['baseFilterConfigs']['edges']
}

type TDateRange = {
  start_date: string
  end_date: string
} | null

const BASE_RUN_STATUS_OPTIONS = [
  { label: 'Disabled', value: 'disabled' },
  { label: 'Done', value: 'done' },
  { label: 'Failed', value: 'failed' },
  { label: 'Pending', value: 'pending' },
  { label: 'Running', value: 'running' },
  { label: 'Snoozed', value: 'snoozed' },
]
const baseRunStatusValues = BASE_RUN_STATUS_OPTIONS.map((o) => o.value)

export const EXPORT_STATUS_OPTIONS = [
  {
    label: 'Review pending',
    value: 'generated',
    toolTipText: 'Base runs that are part of an already exported file, but not reviewed.',
  },
  {
    label: 'Approved',
    value: 'approved',
    toolTipText:
      'Base runs that are part of an already exported file and are approved by the reviewer.',
  },
  {
    label: 'Rejected',
    value: 'rejected',
    toolTipText:
      'Base runs that are part of an already exported file and are rejected by the reviewer.',
  },
] as const
export const exportStatusValues = EXPORT_STATUS_OPTIONS.map((o) => o.value)

export const additionalFilterKeys = ['exported_base_run_status']

const FilterConfiguration = ({ state, dispatch, processData, filterConfigs }: IProps) => {
  const { value: enableExportDedupe } = useGate('enable-export-dedupe')
  const filterableBaseVariables = useMemo(() => {
    const selectedBase = processData?.process.bases.find((base) => base.id === state.baseId)
    // Filter out 'a_' prefixed variables and 'object' type variables
    if (selectedBase)
      return selectedBase.baseVariables
        .filter((v) => !v.type.startsWith('a_') && v.type !== 'object')
        .map((v) => ({ ...v, id: fromGlobalId(v.id) }))
    return []
  }, [processData, state.baseId])
  const processSteps = useMemo(
    () =>
      (processData?.process.steps ?? []).map((step) => ({ ...step, id: fromGlobalId(step.id) })),
    [processData]
  )

  const createdAtFilterValue = state.filters.find((f) => f.key === 'created_at')
    ?.value as TDateRange
  const deliveredAtFilterValue = state.filters.find((f) => f.key === 'delivered_at')
    ?.value as TDateRange
  // by default, all base runs are included
  const baseRunStatusFilterValue =
    (state.filters.find((f) => f.key === 'baseRunStatus')?.value as string[]) ?? baseRunStatusValues

  const exportedBaseRunExcludeValues =
    (state.filters.find((f) => f.key === 'exported_base_run_status')?.value as string[]) ?? []
  // because the state stores the excluded statuses, we're flipping the filter options
  // for the ui to show the correct state
  const exportedBaseRunStatusValues = exportStatusValues.filter(
    (v) => !exportedBaseRunExcludeValues.includes(v)
  )

  const filterConfigOptions = filterConfigs.map((f) => ({
    label: f.node.name,
    value: f.node.id,
  }))

  const updateBaseRunFilter = (key: string, value: unknown, isBaseRunStatus?: boolean) => {
    if (value === null)
      dispatch({
        type: 'REMOVE_FILTERS',
        payload: [key],
      })

    if (state.filters.find((f) => f.key === key)) {
      dispatch({
        type: 'UPDATE_FILTER',
        payload: {
          key,
          value,
        },
      })
    } else {
      dispatch({
        type: 'ADD_FILTERS',
        payload: [
          {
            key,
            value,
            ...(isBaseRunStatus ? { is_base_run_status: true } : {}),
          },
        ],
      })
    }
  }

  const selectFilterConfig = (id?: string) => {
    if (!id) return
    const selectedFilterConfig = filterConfigs.find((f) => f.node.id === id)
    if (!selectedFilterConfig) return

    const extraFilters = selectedFilterConfig.node.config['extra_filters'] ?? []
    const stepRunFilters = selectedFilterConfig.node.config['step_run_filters'] ?? []
    dispatch({
      type: 'SET_STATE',
      payload: {
        filters: extraFilters,
        stepRunFilters,
      },
    })
  }

  return (
    <Stack gap='16px'>
      <Stack direction='row' justifyContent='space-between' gap='8px'>
        <Stack gap='4px'>
          <Typography fontWeight='bold'>
            Customize your time range(s), and data for export below.
          </Typography>
          <Typography>
            Or select a preset from the right to quickly insert preconfigured values.
          </Typography>
        </Stack>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Autocomplete
            options={filterConfigOptions}
            renderInput={(params) => <TextField {...params} label='Use values from a preset...' />}
            size='small'
            sx={{
              width: '250px',
            }}
            onChange={(_, newValue) => selectFilterConfig(newValue?.value)}
          />
          <Tooltip
            arrow
            title={
              <Typography fontSize='12px'>
                Presets are preconfigured
                <br />
                value sets you can apply
                <br /> to your export settings.
              </Typography>
            }>
            <InfoOutlinedIcon sx={{ color: 'grey.500' }} />
          </Tooltip>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' gap='16px' mt='8px'>
        <Typography width='300px'>
          Filter export data by Created by and Delivered by time range(s)
        </Typography>
        <DateFilter
          placeholder='Set Created (Optional)'
          label='Created At'
          reference='Created'
          value={createdAtFilterValue ?? null}
          onChange={(date) => updateBaseRunFilter('created_at', date)}
        />
        <Typography>and</Typography>
        <DateFilter
          placeholder='Set Delivered (Optional)'
          label='Delivered At'
          reference='Finished'
          value={deliveredAtFilterValue ?? null}
          onChange={(date) => updateBaseRunFilter('delivered_at', date)}
        />
      </Stack>

      <Stack direction='row' alignItems='center' gap='16px'>
        <Stack direction='row' alignItems='center' gap={1} width='300px'>
          <Typography>Filter the data by status </Typography>
          <Tooltip
            arrow
            title={
              <Typography fontSize='12px'>
                All base runs will be exported by default. To exclude any, uncheck the relevant
                statuses in the dropdown list.
              </Typography>
            }>
            <InfoOutlinedIcon sx={{ color: 'grey.500' }} />
          </Tooltip>
        </Stack>
        <Select
          multiple
          value={baseRunStatusFilterValue}
          displayEmpty
          onChange={(e) => {
            const value = e.target.value
            const processedValue = typeof value === 'string' ? value.split(',') : value
            updateBaseRunFilter(
              'baseRunStatus',
              processedValue.length ? processedValue : null,
              true
            )
          }}
          input={<OutlinedInput size='small' sx={{ width: '270px' }} />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography color='text.disabled'>Select a status (Optional)</Typography>
            }
            return selected.map(capitalize).join(', ')
          }}>
          {BASE_RUN_STATUS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value} sx={{ p: 0, px: '4px' }}>
              <Checkbox
                checked={baseRunStatusFilterValue.indexOf(option.value) > -1}
                size='small'
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </Stack>

      {enableExportDedupe ? (
        <ExcludeBaseRunsByExportStatus
          optionValues={exportedBaseRunStatusValues}
          updateBaseRunFilter={updateBaseRunFilter}
        />
      ) : null}

      <Box>
        <Typography fontWeight='bold'>Select the specific data</Typography>
        <Typography mb='8px'>
          Stages appear below. Expand and select. Hover any item to set a custom time-range on it.
        </Typography>
        <StepRunFilters state={state} dispatch={dispatch} steps={processSteps} />

        <Typography mt='8px'>
          Base variables appear below. Hover any item to enter a filter.
        </Typography>
        <BaseVariableFilters
          state={state}
          dispatch={dispatch}
          baseVariables={filterableBaseVariables}
        />
      </Box>
    </Stack>
  )
}

export { FilterConfiguration }
