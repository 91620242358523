import { Autocomplete, Box, Popper, TextField } from '@mui/material'
import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react'

import { getShortcut } from '../helpers'
import { IOptionsContextMenuProps } from '../types'

export const OptionsContextMenu = ({
  options = [],
  selectedOption,
  entity,
  selections,
  save,
  setOption,
}: IOptionsContextMenuProps) => {
  const shortcut = useMemo(() => getShortcut(entity), [entity])

  useEffect(() => {
    if (selectedOption) save()
  }, [selectedOption, save])

  const handleChange = useCallback(
    (e: SyntheticEvent<Element, Event>, value: string | null) => {
      e.preventDefault()
      setOption(value as string)
    },
    [setOption]
  )

  return (
    <div className='p-3'>
      <Box sx={{ width: 250 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'gray' }}>
          <span className='whitespace-normal text-xs'>{entity}</span>
          <span className='text-xs'>{shortcut}</span>
        </Box>
        <p className='mt-2 mb-3 flex flex-col whitespace-normal font-semibold'>
          {selections?.map((selection) => (
            <span className='pb-1'>{selection}</span>
          ))}
        </p>
        <Autocomplete
          size='small'
          options={options}
          renderInput={(params) => <TextField {...params} label='Select a value' />}
          PopperComponent={(props) => <Popper {...props} style={{ zIndex: 2000, minWidth: 250 }} />}
          sx={{
            minWidth: 250,
            height: '100%',
          }}
          value={selectedOption}
          onChange={handleChange}
        />
      </Box>
    </div>
  )
}
