import { zodResolver } from '@hookform/resolvers/zod'
import { SnackbarContext } from '@invisible/common/providers'
import {
  getErrorMessage,
  IExportConfigurationsQuery,
  parseResponse,
  toGlobalId,
  useBaseFilterConfigCreateMutation,
  useBaseFilterConfigsQuery,
  useBaseFilterConfigUpdateMutation,
  useExportConfigurationDataQuery,
} from '@invisible/concorde/gql-client'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { isEqual } from 'lodash/fp'
import { useRouter } from 'next/router'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RRule } from 'rrule'

import { useExportCreateOrUpdate } from '../hooks/useExportCreateorUpdate'
import { CreateExportDialogStepper } from './CreateExportDialogStepper'
import { CreatePresetDialog } from './CreatePresetDialog'
import { additionalFilterKeys } from './FilterConfiguration'
import { stateReducer, TSchedulerConfig } from './reducer'
import { DEFAULT_RRULE, DEFAULT_SCHEDULE_STATE } from './SchedulerConstants'
import { formSchema, TFormSchema } from './schema'

interface IProps {
  isOpen: boolean
  onClose: () => void
  exportConfigBeingEdited?: IExportConfigurationsQuery['exportConfigurations']['edges'][0]['node']
}

type FormFields = keyof TFormSchema

const multiTabFormSteps = [
  {
    id: 1,
    fields: ['name', 'description', 'selectedBase'],
  },
  {
    id: 4,
    fields: ['selectedTransformationRule'],
  },
  {
    id: 5,
    fields: ['to', 'cc', 'bcc'],
  },
]

export const initialState = {
  formTab: 1,
  highestFormTabReached: 1,
  name: '',
  description: '',
  baseId: null,
  filters: [],
  stepRunFilters: [],
  transformationRuleId: null,
  filterConfigId: null,
  schedulerPageConfig: DEFAULT_SCHEDULE_STATE,
  recipients: {
    to: [],
    cc: undefined,
    bcc: undefined,
  },
  exportConfigurationId: null,
}

const CreateExportDialog = ({ isOpen, onClose, exportConfigBeingEdited }: IProps) => {
  const { query } = useRouter()
  const { showSnackbar } = useContext(SnackbarContext)
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const { data } = useExportConfigurationDataQuery({
    processId: toGlobalId('Process', query.id as string),
  })

  const [isSavePresetOpen, setIsSavePresetOpen] = useState(false)
  const [isScheduleConfigReset, setIsScheduleConfigReset] = useState(false)
  const [currentScheduleDate, setCurrentScheduleDate] = useState<{ schedule: string; date: Date }>({
    schedule: DEFAULT_SCHEDULE_STATE.taskSchedule,
    date: DEFAULT_RRULE.dtstart,
  })

  const {
    register,
    handleSubmit,
    control,
    trigger,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<TFormSchema>({
    resolver: zodResolver(formSchema),
  })

  const { data: baseFilterConfigData } = useBaseFilterConfigsQuery(
    {
      filters: {
        baseId: state.baseId,
        isReusable: {
          exact: true,
        },
      },
    },
    { enabled: !!state.baseId }
  )

  const { updateExport, isExportUpdateLoading } = useExportCreateOrUpdate()
  const { mutateAsync: createFilterConfig, isLoading: isCreateFilterConfigLoading } =
    useBaseFilterConfigCreateMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      },
    })
  const { mutateAsync: updateFilterConfig, isLoading: isUpdateFilterConfigLoading } =
    useBaseFilterConfigUpdateMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      },
    })

  useEffect(() => {
    if (!exportConfigBeingEdited) return

    // Persists the state of the export configuration being edited
    setValue('name', exportConfigBeingEdited.name ?? '')
    setValue('description', exportConfigBeingEdited.description ?? '')
    setValue('selectedBase', {
      label: exportConfigBeingEdited.base.name,
      value: exportConfigBeingEdited.base.id,
    })
    setValue('selectedTransformationRule', {
      label: exportConfigBeingEdited.transformationRule.name ?? '',
      value: exportConfigBeingEdited.transformationRule.id,
      expression: exportConfigBeingEdited.transformationRule.expression,
    })
    setValue('bcc', exportConfigBeingEdited.recipients.bcc ?? [])
    setValue('cc', exportConfigBeingEdited.recipients.cc ?? [])
    setValue('to', exportConfigBeingEdited.recipients.to ?? [])

    let schedulerPageConfig: TSchedulerConfig = DEFAULT_SCHEDULE_STATE
    if (exportConfigBeingEdited.exportConfigurationTaskScheduleConfigs.length > 0) {
      const rruleString = exportConfigBeingEdited.exportConfigurationTaskScheduleConfigs[0].rrule
      const rruleDescription =
        exportConfigBeingEdited.exportConfigurationTaskScheduleConfigs[0].description
      const rrule = RRule.fromString(rruleString)
      schedulerPageConfig = {
        freq: rrule.options.freq,
        interval: rrule.options.interval,
        dtstart: rrule.options.dtstart,
        byweekday: rrule.options.byweekday,
        byhour: rrule.options.byhour[0] as number,
        byminute: rrule.options.byminute[0] as number,
        until: rrule.options.until,
        count: rrule.options.count,
        tzid: rrule.options.tzid,
        taskSchedule: !rruleDescription
          ? 'default'
          : rrule.options.freq === 3 && rrule.options.count === 1
          ? 'once'
          : 'custom',
        isCustom: rrule && rrule.options.count !== 1 ? true : false,
        endCondition: rrule.options.count ? 'after' : rrule.options.until ? 'on' : 'never',
        description: rruleDescription,
        rruleString: rruleString,
        monthOption:
          rrule.options.freq !== RRule.MONTHLY
            ? null
            : rrule.options.bymonthday
            ? 'day'
            : 'weekday',
        bymonthday: rrule.options.bymonthday,
        bysetpos: rrule.options.bysetpos,
      }
    }

    const extraFilters = exportConfigBeingEdited.filterConfig.config.extra_filters ?? []
    const allFilters = [
      ...extraFilters,
      {
        key: 'exported_base_run_status',
        value: exportConfigBeingEdited.filterConfig.config.exported_base_run_status ?? [],
      },
    ]

    dispatch({
      type: 'SET_STATE',
      payload: {
        filterConfigId: exportConfigBeingEdited.filterConfig.id ?? null,
        filters: allFilters,
        stepRunFilters: exportConfigBeingEdited.filterConfig.config.step_run_filters ?? [],
        exportConfigurationId: exportConfigBeingEdited.id,
        formTab: 1,
        schedulerPageConfig: schedulerPageConfig,
      },
    })
  }, [exportConfigBeingEdited?.id])

  const handleTabChange = async (page?: number) => {
    // We allow the user to navigate to any tab they have already visited
    if (page && page <= state.highestFormTabReached)
      return dispatch({ type: 'SET_STATE', payload: { formTab: page } })

    // Check if rrule is valid
    if (currentScheduleDate.schedule !== 'default') {
      if (currentScheduleDate.date < new Date(Date.now() + 15 * 60000)) {
        showSnackbar({
          message: 'The scheduled start time must be at least 15 minutes in the future.',
          variant: 'error',
        })
        if ([3, 5].includes(state.formTab)) return
      }
    }

    const fields = multiTabFormSteps.find((step) => step.id === state.formTab)?.fields

    const output = await trigger(fields as FormFields[], { shouldFocus: true })
    if (fields && !output) return

    const values = getValues()

    // details tab
    if (state.formTab === 1) {
      dispatch({
        type: 'SET_STATE',
        payload: {
          name: values.name,
          description: values.description,
          baseId: values.selectedBase.value,
        },
      })
    }

    // Filter configuration tab
    if (state.formTab === 2 && !state.filterConfigId) {
      const createdConfig = await createFilterConfig({
        data: {
          name: state.name,
          description: state.description,
          baseId: state.baseId,
          config: {
            extra_filters: state.filters.filter((f) => !additionalFilterKeys.includes(f.key)),
            step_run_filters: state.stepRunFilters,
            exported_base_run_status: state.filters.find(
              (f) => f.key === 'exported_base_run_status'
            )?.value,
          },
          isReusable: false,
        },
      })
      const data = parseResponse(createdConfig.baseFilterConfigCreate, (_, message) => {
        showSnackbar({
          message,
          variant: 'error',
        })
      })
      if (!data) {
        return
      }

      dispatch({
        type: 'SET_STATE',
        payload: {
          filterConfigId: data.id,
        },
      })
    }

    // If a filter config already exists, simply update it with the new config
    if (state.formTab === 2 && state.filterConfigId) {
      await updateFilterConfig({
        data: {
          baseFilterConfigId: state.filterConfigId,
          config: {
            extra_filters: state.filters.filter((f) => !additionalFilterKeys.includes(f.key)),
            // Filter out step run filters that have no filters applied
            step_run_filters: state.stepRunFilters.filter(
              (f) => !isEqual(f, { step_id: f.step_id })
            ),
            exported_base_run_status: state.filters.find(
              (f) => f.key === 'exported_base_run_status'
            )?.value,
          },
        },
      })
    }

    // preview tab
    if (state.formTab === 4) {
      dispatch({
        type: 'SET_STATE',
        payload: {
          transformationRuleId: values.selectedTransformationRule.value,
        },
      })
    }

    // recipients tab
    if (state.formTab === 5) {
      const recipients = {
        to: values.to,
        cc: values.cc,
        bcc: values.bcc,
      }
      dispatch({
        type: 'SET_STATE',
        payload: {
          recipients,
        },
      })

      // If an export config id is in state, we simply update it and close
      if (state.exportConfigurationId) {
        await updateExport({
          id: state.exportConfigurationId,
          data: {
            name: state.name,
            description: state.description,
            transformationRuleId: state.transformationRuleId,
            rruleString:
              state.schedulerPageConfig.rruleString && state.schedulerPageConfig.description
                ? state.schedulerPageConfig.rruleString
                : null,
            rruleDescription:
              state.schedulerPageConfig.rruleString && state.schedulerPageConfig.description
                ? state.schedulerPageConfig.description
                : null,
            recipients,
          },
        })
      } else {
        setIsSavePresetOpen(true)
      }
      onClose()
    }

    clearErrors()

    dispatch({ type: 'NEXT_PAGE' })
  }

  const handleClose = () => {
    dispatch({
      type: 'SET_STATE',
      payload: initialState,
    })
    reset()
    onClose()
    setIsSavePresetOpen(false)
    setIsScheduleConfigReset(true)
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth='xl' fullWidth>
        <DialogTitle sx={{ pb: 0 }}>Export process data</DialogTitle>
        <DialogContent sx={{ height: '90vh', px: '16px' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 8,
            }}>
            <CloseIcon />
          </IconButton>

          <CreateExportDialogStepper
            state={state}
            dispatch={dispatch}
            filterConfigs={baseFilterConfigData?.baseFilterConfigs?.edges ?? []}
            processData={data}
            handleTabChange={handleTabChange}
            reactHookForm={{
              register,
              errors,
              control,
              setValue,
            }}
            hasScheduleBeenEdited={!isScheduleConfigReset || exportConfigBeingEdited ? true : false}
            setIsScheduleConfigReset={setIsScheduleConfigReset}
            setCurrentScheduleDate={setCurrentScheduleDate}
          />
        </DialogContent>

        <DialogActions>
          {/* Not visible on the first tab */}
          {state.formTab !== 1 ? (
            <Button
              sx={{ fontWeight: 'normal' }}
              onClick={() => dispatch({ type: 'PREVIOUS_PAGE' })}>
              BACK
            </Button>
          ) : null}

          <LoadingButton
            type='submit'
            loading={
              isCreateFilterConfigLoading || isUpdateFilterConfigLoading || isExportUpdateLoading
            }
            sx={{ fontWeight: 'normal' }}
            onClick={() => handleTabChange()}>
            {state.formTab !== 5 ? 'NEXT' : 'SAVE'}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <CreatePresetDialog
        state={state}
        dispatch={dispatch}
        open={isSavePresetOpen}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </>
  )
}

export { CreateExportDialog }
