import {
  mapGraphqlToUnifiedProcess,
  TBaseVariable,
  TProcess,
  TProcessByIdBase,
  TProcessStepGoTo,
  TProcessStepTemplate,
  useProcessById,
  useStepTemplates,
} from '@invisible/common/components/process-base'
import { classNames, combineRefs } from '@invisible/common/helpers'
import {
  DND_DRAG_TYPES,
  OPTIMISTIC_STEP_ID,
  useStore,
} from '@invisible/common/stores/process-store'
import { IProcessByIdQuery, toGlobalId } from '@invisible/concorde/gql-client'
import { useContext, useMutation } from '@invisible/trpc/client'
import { PlusIcon } from '@invisible/ui/icons'
import { BRANCH_STEP_TEMPLATE_ID, MAP_STEP_TEMPLATE_ID } from '@invisible/ultron/shared'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { BranchStepMeta } from '@invisible/ultron/zod'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useQueryClient } from 'react-query'
import {
  getBezierPath,
  Position,
  useEdges,
  useNodes,
  useReactFlow,
  useStore as useReactFlowStore,
} from 'reactflow'
import { useGate } from 'statsig-react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { v4 as uuid } from 'uuid'
import shallow from 'zustand/shallow'

const BUTTON_SIZE = 25

// Largely just a placeholder to indicate when edges can be dropped on

// eslint-disable-next-line @typescript-eslint/ban-types
const PlusButton: FC<{
  goFromStepId: string
  goToStepId: string
  isActiveEdge: boolean
}> = ({ isActiveEdge, goFromStepId, goToStepId }) => {
  if (goFromStepId === OPTIMISTIC_STEP_ID || goToStepId === OPTIMISTIC_STEP_ID) return null

  return (
    <button
      className={classNames(
        'text-primary pointer-events-none m-0 h-6 w-6 cursor-pointer overflow-visible rounded-full border border-solid bg-white p-0',
        isActiveEdge ? 'border-black' : 'border-primary'
      )}>
      <PlusIcon width={10} height={10} className={isActiveEdge ? 'text-black' : 'text-primary'} />
    </button>
  )
}

interface IEdgeProps {
  id: string
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
  withPlusButton?: boolean
  dashed: boolean
  data: {
    isAndEdge: boolean
    stepGoTo: TProcessStepGoTo
    processRootBaseId: string
    isNonEditableProcess: boolean
  }
}

const conditionSymbols: Record<BranchStepMeta.TBranch['condition']['name'], string> = {
  eq: '=',
  neq: '≠',
  isNull: '= NULL',
  isNotNull: '≠ NULL',
}

function usePointsOnPath(edgePath: string, lengths: number[]) {
  const [points, setPoints] = useState<(DOMPoint | undefined)[]>([])
  const pathRef = useRef<SVGPathElement>(null)

  useDeepCompareEffect(() => {
    const pathEl = pathRef.current
    if (pathEl) {
      const totalLength = pathEl.getTotalLength()
      setPoints(lengths.map((length) => pathEl.getPointAtLength(totalLength * length)))
    }
  }, [edgePath, lengths])
  return { points, pathRef }
}

const computeBranchName = (
  goFromStep: TProcessStepGoTo['goFromStep'],
  goToStepId: string,
  bases: TProcessByIdBase[]
) => {
  if (goFromStep?.stepTemplate?.subtype !== 'branch') return ''

  const meta = goFromStep.meta as BranchStepMeta.TSchema | BranchStepMeta.TNestedBranchStepSchema

  if ('nestedBranches' in meta) {
    const branch = meta.nestedBranches?.find((branch) => branch.stepId === goToStepId)
    if (!branch && meta.defaultStepId === goToStepId) return 'Default'
    return branch?.branchName ?? ''
  }

  const branch = meta.branches.find((branch) => branch.stepId === goToStepId)

  if (!branch && meta.defaultStepId === goToStepId) return 'Default'
  if (!branch) return ''

  const baseVariables = (bases ?? []).reduce((acc: TBaseVariable[], curr: TProcessByIdBase) => {
    acc = [...acc, ...curr.baseVariables]
    return acc
  }, [])

  const operator = conditionSymbols[branch.condition.name]
  const operand1 =
    branch.condition.args[0].type === 'constant'
      ? branch.condition.args[0]?.value
      : baseVariables.find((v) => v.id === (branch.condition.args[0] as any)?.baseVariableId)
          ?.name ?? ''
  const operand2 =
    branch.condition.args?.[1]?.type === 'constant'
      ? branch.condition.args?.[1]?.value
      : branch.condition.args?.[1]?.type === 'variable'
      ? baseVariables.find((v) => v.id === (branch.condition.args[1] as any)?.baseVariableId)?.name
      : ''

  return `${operand1} ${operator} ${operand2}`
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DefaultEdge: FC<IEdgeProps> = ({
  id,
  withPlusButton,
  sourceX,
  sourceY,
  targetX,
  targetY,
  dashed,
  data,
  sourcePosition,
  targetPosition,
}) => {
  const { value: enableStepTemplates } = useGate('enable-graphql-steptemplateinfo-query')
  const { value: isGraphqlEnabled } = useGate('enable-graphql-process-by-id-query')
  const graphqlQueryClient = useQueryClient()
  const reactQueryContext = useContext()
  const [mapStepModalOpen, setMapStepModalOpen] = useState(false)
  const canvasBounds = useReactFlowStore((s) => s.domNode?.getBoundingClientRect())
  const { project } = useReactFlow()
  const [stepTemplateDetails, setStepTemplateDetails] = useState<TProcessStepTemplate | null>(null)

  const { currentLayoutId, addStepPosition } = useStore(
    useCallback(
      (state) => ({
        currentLayoutId: state.currentLayoutId,
        addStepPosition: state.addStepPosition,
      }),
      []
    ),
    shallow
  )
  const nodes = useNodes()
  const edges = useEdges()
  const { data: process } = useProcessById({
    id: data.stepGoTo.processId,
  })

  const { data: stepTemplates } = useStepTemplates({ enabled: !enableStepTemplates })

  const queryKey = isGraphqlEnabled
    ? ['ProcessById', { id: toGlobalId('ProcessType', process?.id) }]
    : ['process.findByIdWithStepsAndStepGoTos', { id: process?.id }]

  const queryClient = isGraphqlEnabled ? graphqlQueryClient : reactQueryContext.queryClient

  const { mutateAsync: createStep } = useMutation('step.create', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('process.getLayout')
      graphqlQueryClient.invalidateQueries('ProcessById')
      reactQueryContext.invalidateQueries('process.findByIdWithStepsAndStepGoTos')
    },
    onMutate: async (variables) => {
      await graphqlQueryClient.cancelQueries('ProcessById')
      await reactQueryContext.queryClient.cancelQueries('process.findByIdWithStepsAndStepGoTos')
      const stepTemplate = !enableStepTemplates
        ? stepTemplates?.find((s) => s.id === variables.stepTemplateId)
        : null
      queryClient.setQueryData(
        queryKey,
        // @ts-expect-error Wrong types
        (prevData: TProcess | IProcessByIdQuery) => {
          if (!prevData) return
          const mappedPrevData =
            isGraphqlEnabled && 'process' in prevData
              ? (mapGraphqlToUnifiedProcess(prevData) as TProcess)
              : (prevData as TProcess)
          return {
            ...mappedPrevData,
            steps: [
              ...mappedPrevData.steps,
              {
                id: OPTIMISTIC_STEP_ID,
                name: stepTemplate ? stepTemplate.name ?? '' : stepTemplateDetails?.name ?? '',
                processId: process?.id,
                position: 1,
                meta: variables.meta,
                stepTemplateId: variables.stepTemplateId,
                stepTemplate: {
                  ...(stepTemplate ? stepTemplate : stepTemplateDetails),
                },
              },
            ],
            stepGoTos: [
              ...mappedPrevData.stepGoTos.filter(
                (s) =>
                  s.goFromStepId !== stepGoTo?.goFromStepId || s.goToStepId !== stepGoTo?.goToStepId
              ),
              {
                goFromStepId: stepGoTo?.goFromStepId,
                goToStepId: OPTIMISTIC_STEP_ID,
                processId: process?.id,
              },
              {
                goFromStepId: OPTIMISTIC_STEP_ID,
                goToStepId: stepGoTo?.goToStepId,
                processId: process?.id,
              },
            ],
          }
        }
      )

      if (!currentLayoutId || !variables.xCoordinate || !variables.yCoordinate) return
      reactQueryContext.queryClient.setQueryData<inferQueryOutput<'process.getLayout'> | undefined>(
        [
          'process.getLayout',
          {
            layoutId: currentLayoutId,
          },
        ],
        (prevData) => {
          if (!prevData) return

          return {
            ...prevData,
            stepPositions: [
              ...prevData.stepPositions,
              {
                stepId: OPTIMISTIC_STEP_ID,
                id: uuid(),
                processId: process?.id,
                layoutId: currentLayoutId,
                xCoordinate: variables.xCoordinate as number,
                yCoordinate: variables.yCoordinate as number,
                createdAt: new Date(),
                updatedAt: new Date(),
              },
            ],
          }
        }
      )
    },
  })

  const { mutateAsync: createBranchStep } = useMutation('branchStep.create', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('process.getLayout')
      graphqlQueryClient.invalidateQueries('ProcessById')
      reactQueryContext.invalidateQueries('process.findByIdWithStepsAndStepGoTos')
    },
    onMutate: async (variables) => {
      await graphqlQueryClient.cancelQueries('ProcessById')
      await reactQueryContext.queryClient.cancelQueries('process.findByIdWithStepsAndStepGoTos')
      queryClient.setQueryData(
        queryKey,
        // @ts-expect-error Wrong types
        (prevData: TProcess | IProcessByIdQuery) => {
          if (!prevData) return
          const mappedPrevData =
            isGraphqlEnabled && 'process' in prevData
              ? (mapGraphqlToUnifiedProcess(prevData) as TProcess)
              : (prevData as TProcess)
          return {
            ...mappedPrevData,
            steps: [
              ...mappedPrevData.steps,
              {
                id: OPTIMISTIC_STEP_ID,
                name: 'Branch',
                processId: process?.id,
                position: 1,
                meta: variables.meta,
                stepTemplateId: BRANCH_STEP_TEMPLATE_ID,
                stepTemplate: {
                  id: BRANCH_STEP_TEMPLATE_ID,
                  name: 'Branch',
                },
              },
            ],
            stepGoTos: [
              ...mappedPrevData.stepGoTos.filter(
                (s) =>
                  s.goFromStepId !== stepGoTo?.goFromStepId || s.goToStepId !== stepGoTo?.goToStepId
              ),
              {
                goFromStepId: stepGoTo?.goFromStepId,
                goToStepId: OPTIMISTIC_STEP_ID,
                processId: process?.id,
              },
              {
                goFromStepId: OPTIMISTIC_STEP_ID,
                goToStepId: stepGoTo?.goToStepId,
                processId: process?.id,
              },
            ],
          }
        }
      )

      if (!currentLayoutId || !variables.xCoordinate || !variables.yCoordinate) return
      reactQueryContext.queryClient.setQueryData<inferQueryOutput<'process.getLayout'> | undefined>(
        [
          'process.getLayout',
          {
            layoutId: currentLayoutId,
          },
        ],
        (prevData) => {
          if (!prevData) return

          return {
            ...prevData,
            stepPositions: [
              ...prevData.stepPositions,
              {
                stepId: OPTIMISTIC_STEP_ID,
                id: uuid(),
                processId: process?.id,
                layoutId: currentLayoutId,
                xCoordinate: variables.xCoordinate as number,
                yCoordinate: variables.yCoordinate as number,
                createdAt: new Date(),
                updatedAt: new Date(),
              },
            ],
          }
        }
      )
    },
  })

  const [{ isOver }, drop] = useDrop(() => ({
    accept: DND_DRAG_TYPES.STEP_TEMPLATE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      getClientOffset: monitor.getClientOffset(),
    }),
    canDrop: () => !data.isNonEditableProcess,
    drop: async (item: any, monitor) => {
      setStepTemplateDetails({
        id: item.id,
        name: item.data.name,
        icon: item.data.icon,
        type: item.data.type,
        subtype: item.data.subtype,
        companyId: item.data.companyId,
        service: item.data.service,
        costInMillicents: item.data.costInMillicents,
        credentialType: item.data.credentialType,
        stepTemplateVariables: item.data.stepTemplateVariables,
      })

      const clientOffset = monitor.getClientOffset()
      const stepPosition =
        clientOffset && canvasBounds
          ? project({
              x: clientOffset.x - canvasBounds.left,
              y: clientOffset.y - canvasBounds.top,
            })
          : null
      if (stepPosition) addStepPosition(stepPosition)

      //Map Step
      if (item.id === MAP_STEP_TEMPLATE_ID) {
        setMapStepModalOpen(true)
        return
      }

      // Branch step
      if (item.data.subtype === 'branch') {
        await createBranchStep({
          baseId: process?.rootBaseId as string,
          keepExistingStepGoTo: false,
          meta: {
            nestedBranches: [],
            defaultStepId: stepGoTo?.goToStepId as string,
          },
          goFromStepId: stepGoTo?.goFromStepId,
          goToStepId: stepGoTo?.goToStepId,
          ...(stepPosition && currentLayoutId
            ? {
                layoutId: currentLayoutId,
                xCoordinate: stepPosition.x,
                yCoordinate: stepPosition.y,
              }
            : {}),
        })
        return
      }

      await createStep({
        baseId: process?.rootBaseId as string,
        stepTemplateId: item.id,
        meta: item.data?.meta ?? null,
        keepExistingStepGoTo: false,
        goFromStepId: stepGoTo?.goFromStepId,
        goToStepId: stepGoTo?.goToStepId,
        ...(stepPosition && currentLayoutId
          ? {
              layoutId: currentLayoutId,
              xCoordinate: stepPosition.x,
              yCoordinate: stepPosition.y,
            }
          : {}),
      })
    },
  }))

  const stepGoTo = useMemo(
    () =>
      process?.stepGoTos.find(
        (s) =>
          s.goFromStepId === data.stepGoTo.goFromStepId && s.goToStepId === data.stepGoTo.goToStepId
      ),
    [data.stepGoTo.goFromStepId, data.stepGoTo.goToStepId, process?.stepGoTos]
  )

  const branchName = useMemo(
    () =>
      stepGoTo
        ? computeBranchName(
            stepGoTo?.goFromStep as TProcessStepGoTo['goFromStep'],
            stepGoTo?.goToStepId,
            (process?.bases as TProcessByIdBase[]) ?? []
          )
        : '',
    [stepGoTo, process?.bases]
  )
  const [customEdgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  })

  const {
    points: [centerPoint, labelPoint],
    pathRef,
  } = usePointsOnPath(customEdgePath, [0.3, 0.6])

  const isOnActiveStep = useMemo(() => {
    const activeNode = nodes.find((node) => node.selected === true)
    const activeEdge = edges.find((edge) => edge.selected === true)
    if (!activeNode && !activeEdge) return false
    return stepGoTo?.goFromStepId === activeNode?.id || activeEdge?.id === id
  }, [stepGoTo?.goFromStepId, edges, id, nodes])

  if (!data || !process) return null

  return (
    <>
      <path
        id={id}
        className={classNames(
          'react-flow__edge-path w-10 cursor-pointer text-red-600',
          isOnActiveStep ? '!stroke-black' : '!stroke-primary'
        )}
        strokeDasharray={dashed ? '5,5' : '0,0'}
        d={customEdgePath}
        markerEnd={isOnActiveStep ? 'url(#active-marker)' : 'url(#inactive-marker)'}
        ref={pathRef}
      />
      {/* Duplicate path we use to present a wider area for dropping */}
      {withPlusButton ? (
        <path
          id={id}
          className={classNames(
            'react-flow__edge-path !stroke-purple-400 !stroke-[100px]',
            isOver ? 'opacity-20' : 'opacity-0'
          )}
          d={customEdgePath}
          ref={combineRefs(pathRef, drop)}
        />
      ) : null}

      {branchName ? (
        <foreignObject
          width='160px'
          height='40px'
          x={(labelPoint?.x ?? 0) - 80}
          y={labelPoint?.y}
          requiredExtensions='http://www.w3.org/1999/xhtml'>
          <span
            className={classNames(
              'box-border inline-block w-40 overflow-hidden truncate rounded-lg border border-solid bg-white py-1.5 px-2 text-center',
              isOnActiveStep ? 'border-black' : 'border-primary'
            )}
            title={branchName}>
            {branchName}
          </span>
        </foreignObject>
      ) : null}

      {stepGoTo && withPlusButton ? (
        <foreignObject
          width={BUTTON_SIZE}
          height={BUTTON_SIZE}
          x={(centerPoint?.x ?? 0) - BUTTON_SIZE / 2}
          y={(centerPoint?.y ?? 0) - BUTTON_SIZE / 2}
          className='pointer-events-none'
          requiredExtensions='http://www.w3.org/1999/xhtml'>
          <PlusButton
            goFromStepId={stepGoTo.goFromStepId}
            goToStepId={stepGoTo.goToStepId}
            isActiveEdge={isOnActiveStep}
          />
        </foreignObject>
      ) : null}
    </>
  )
}

export { DefaultEdge }
