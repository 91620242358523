import { Text } from '@invisible/ui/text'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, List, ListItem } from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'

import { getShortcut } from '../helpers'
import { IAnnotationAccordionProps } from '../types'

const AnnotationHoverOrActiveSX = {
  fontWeight: 500,
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
}

export const AnnotationAccordion = ({
  entity,
  annotations,
  deleteAnnotation,
}: IAnnotationAccordionProps) => (
  <Accordion square disableGutters sx={{ border: '0' }} key={entity}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' alignItems='center'>
        <Typography variant='subtitle2' noWrap>
          {entity}
        </Typography>
        <Text color='gray' ml={1}>
          {getShortcut(entity)}
        </Text>
        <Text fontWeight='bold' ml={1}>
          ({annotations.length})
        </Text>
      </Box>
    </AccordionSummary>
    <AccordionDetails sx={{ p: 0, px: 2 }}>
      <List sx={{ p: 0 }}>
        {annotations.map((annotation) => (
          <ListItem
            sx={{
              borderRadius: '0px',
              '&:hover': AnnotationHoverOrActiveSX,
            }}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                width: '100%',
                fontSize: 13,
              }}>
              <Box>
                <Text color='#363636'>{annotation.text}</Text>
                <Text color='gray'>{annotation.option}</Text>
              </Box>
              <RemoveIcon
                onClick={() => deleteAnnotation(annotation.id as string)}
                sx={{ cursor: 'pointer' }}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </AccordionDetails>
  </Accordion>
)
