import { useEffect, useRef } from 'react'
import React from 'react'

import { getShortcut } from './helpers'
import { IHighlightedTextProps } from './types'

const HighlightedText = ({
  id,
  start,
  end,
  text,
  entity,
  option,
  dispatcher,
  isHighlighted,
}: IHighlightedTextProps) => {
  const ref = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    if (isHighlighted) {
      dispatcher({ anchorEl: ref.current })
    }
  }, [isHighlighted, dispatcher])

  const openContextMenu = () => {
    dispatcher({
      anchorEl: ref.current,
      annotation: { start, end, text, id, option, entity },
    })
  }

  return (
    <mark
      ref={ref}
      onClick={openContextMenu}
      data-start={start}
      data-end={end}
      className='relative inline-block leading-none'>
      {text}
      {entity ? (
        <span className='absolute -top-[10px] right-0 text-[0.65rem]'>
          <mark className='ml-0.5 px-0.5'>{getShortcut(entity)}</mark>
        </span>
      ) : null}
    </mark>
  )
}

const Split = (props: IHighlightedTextProps) => {
  if (props.mark) return <HighlightedText {...props} />

  return (
    <span data-start={props.start} data-end={props.end}>
      {props.text}
    </span>
  )
}

export default Split
