import {
  TBaseVariable,
  TProcessByIdBase,
  TProcessStepGoTo,
  useProcessById,
} from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { BranchStepMeta } from '@invisible/ultron/zod'
import { FC, useMemo, useRef, useState } from 'react'
import { getBezierPath, Position, useEdges, useNodes } from 'reactflow'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { conditionSymbols } from '../common/constants'

interface IEdgeProps {
  id: string
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
  withPlusButton?: boolean
  data: {
    isAndEdge: boolean
    stepGoTo: TProcessStepGoTo
    processRootBaseId: string
    isNonEditableProcess: boolean
  }
}

function usePointsOnPath(edgePath: string, lengths: number[]) {
  const [points, setPoints] = useState<(DOMPoint | undefined)[]>([])
  const pathRef = useRef<SVGPathElement>(null)

  useDeepCompareEffect(() => {
    const pathEl = pathRef.current
    if (pathEl) {
      const totalLength = pathEl.getTotalLength()
      setPoints(lengths.map((length) => pathEl.getPointAtLength(totalLength * length)))
    }
  }, [edgePath, lengths])
  return { points, pathRef }
}

const computeBranchName = (
  goFromStep: TProcessStepGoTo['goFromStep'],
  goToStepId: string,
  bases: TProcessByIdBase[]
) => {
  const result = BranchStepMeta.schema
    .or(BranchStepMeta.nestedBranchStepSchema)
    .safeParse(goFromStep.meta)
  if (!result.success) return ''

  const meta = result.data
  if ('nestedBranches' in meta) {
    const branch = meta.nestedBranches?.find((branch) => branch.stepId === goToStepId)
    if (!branch && meta.defaultStepId === goToStepId) return 'Default'
    return branch?.branchName ?? ''
  }

  if (goFromStep?.stepTemplate?.subtype !== 'branch') return ''

  const branch = meta.branches.find((branch) => branch.stepId === goToStepId)

  if (!branch && meta.defaultStepId === goToStepId) return 'Default'
  if (!branch) return ''

  const baseVariables = (bases ?? []).reduce((acc: TBaseVariable[], curr: TProcessByIdBase) => {
    acc = [...acc, ...curr.baseVariables]
    return acc
  }, [])

  const operator = conditionSymbols[branch.condition.name]
  const operand1 =
    branch.condition.args[0].type === 'constant'
      ? branch.condition.args[0]?.value
      : baseVariables.find((v) => v.id === (branch.condition.args[0] as any)?.baseVariableId)
          ?.name ?? ''
  const operand2 =
    branch.condition.args?.[1]?.type === 'constant'
      ? branch.condition.args?.[1]?.value
      : branch.condition.args?.[1]?.type === 'variable'
      ? baseVariables.find((v) => v.id === (branch.condition.args[1] as any)?.baseVariableId)?.name
      : ''

  return `${operand1} ${operator} ${operand2}`
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DefaultEdge: FC<IEdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, data }) => {
  const nodes = useNodes()
  const edges = useEdges()
  const { data: process } = useProcessById({
    id: data.stepGoTo.processId,
  })

  const stepGoTo = useMemo(
    () =>
      process?.stepGoTos.find(
        (s) =>
          s.goFromStepId === data.stepGoTo.goFromStepId && s.goToStepId === data.stepGoTo.goToStepId
      ),
    [data.stepGoTo.goFromStepId, data.stepGoTo.goToStepId, process?.stepGoTos]
  )

  const branchName = useMemo(
    () =>
      stepGoTo
        ? computeBranchName(stepGoTo?.goFromStep, stepGoTo?.goToStepId, process?.bases ?? [])
        : '',
    [stepGoTo, process?.bases]
  )
  const [customEdgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })
  const {
    points: [, labelPoint],
    pathRef,
  } = usePointsOnPath(customEdgePath, [0.3, 0.6])

  const isOnActiveStep = useMemo(() => {
    const activeNode = nodes.find((node) => node.selected === true)
    const activeEdge = edges.find((edge) => edge.selected === true)
    if (!activeNode && !activeEdge) return false
    return stepGoTo?.goFromStepId === activeNode?.id || activeEdge?.id === id
  }, [stepGoTo?.goFromStepId, edges, id, nodes])

  if (!data) return null

  return (
    <>
      <path
        id={id}
        className={classNames(
          'react-flow__edge-path w-10 cursor-pointer text-red-600',
          isOnActiveStep ? '!stroke-rose-500' : '!stroke-primary'
        )}
        d={customEdgePath}
        markerEnd={isOnActiveStep ? 'url(#active-marker)' : 'url(#inactive-marker)'}
        ref={pathRef}
      />

      {branchName ? (
        <foreignObject
          width='160px'
          height='40px'
          x={(labelPoint?.x ?? 0) - 80}
          y={labelPoint?.y}
          requiredExtensions='http://www.w3.org/1999/xhtml'>
          <span
            className={classNames(
              'box-border inline-block w-40 overflow-hidden truncate rounded-lg border border-solid bg-white py-1.5 px-2 text-center',
              isOnActiveStep ? 'border-rose-500' : 'border-primary'
            )}
            title={branchName}>
            {branchName}
          </span>
        </foreignObject>
      ) : null}
    </>
  )
}

export { DefaultEdge }
