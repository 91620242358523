import { BaseViewMeta } from '@invisible/ultron/zod'

type TKeyValue = { key: string; value: string }[]
type TStepRunFilters = NonNullable<BaseViewMeta.TSchema['stepRunFilters']>

type TSchedulerConfig = {
  freq: number
  interval: number | null
  dtstart: Date
  byweekday: number[] | null
  byhour: number
  byminute: number
  until: Date | null
  count: number | null
  tzid: string | null
  taskSchedule: string
  isCustom: boolean
  endCondition: string | null
  description: string | null | undefined
  rruleString: string | null | undefined
  monthOption: string | null
  bymonthday: number[] | null
  bysetpos: number[] | null
}

type TReducerState = {
  name: string
  description: string
  baseId: string | null
  formTab: number
  highestFormTabReached: number
  filters: {
    key: string
    value: unknown
  }[]
  stepRunFilters: TStepRunFilters
  transformationRuleId: string | null
  filterConfigId: string | null
  schedulerPageConfig: TSchedulerConfig
  recipients: {
    to: string[]
    cc?: string[]
    bcc?: string[]
  }
  exportConfigurationId: string | null
}

type TReducerAction =
  | { type: 'SET_STATE'; payload: Partial<TReducerState> }
  | { type: 'NEXT_PAGE' }
  | { type: 'PREVIOUS_PAGE' }
  | { type: 'ADD_FILTERS'; payload: { key: string; value: unknown }[]; is_base_run_status?: true }
  | {
      type: 'UPSERT_FILTER'
      payload: { key: string; value: unknown; is_base_run_status?: true; is_step_status?: true }
    }
  | { type: 'REMOVE_FILTERS'; payload: string[] }
  | { type: 'UPDATE_FILTER'; payload: { key: string; value: unknown } }
  | { type: 'ADD_STEP_RUN_FILTERS'; payload: TStepRunFilters }
  | {
      type: 'UPSERT_STEP_RUN_FILTER'
      payload: { key: string; value: TStepRunFilters[number] } | null
    }
  | { type: 'REMOVE_STEP_RUN_FILTERS'; payload: string[] }
  | { type: 'UPDATE_STEP_RUN_FILTER'; payload: { stepId: string; key: string; value: unknown } }
  | { type: 'UPDATE_SCHEDULER_PAGE_CONFIG'; payload: Partial<TSchedulerConfig> }

const stateReducer = (state: TReducerState, action: TReducerAction): TReducerState => {
  switch (action.type) {
    case 'SET_STATE': {
      return { ...state, ...action.payload }
    }
    case 'NEXT_PAGE': {
      const formTab = Math.min(state.formTab + 1, 5)
      return {
        ...state,
        formTab,
        highestFormTabReached: Math.max(formTab, state.highestFormTabReached),
      }
    }
    case 'PREVIOUS_PAGE': {
      return { ...state, formTab: Math.max(state.formTab - 1, 1) }
    }

    case 'UPSERT_FILTER': {
      if (state.filters.some((f) => f.key === action.payload.key)) {
        return {
          ...state,
          filters: state.filters.map((filter) =>
            filter.key === action.payload.key ? action.payload : filter
          ),
        }
      }
      return {
        ...state,
        filters: [...state.filters, action.payload],
      }
    }
    case 'ADD_FILTERS': {
      return {
        ...state,
        filters: [
          ...state.filters,
          ...action.payload.filter((f) => !state.filters.some((s) => s.key === f.key)),
        ],
      }
    }
    case 'REMOVE_FILTERS': {
      return {
        ...state,
        filters: state.filters.filter((filter) => !action.payload.includes(filter.key)),
      }
    }
    case 'UPDATE_FILTER': {
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (filter.key === action.payload.key) {
            return {
              ...filter,
              value: action.payload.value,
            }
          }
          return filter
        }),
      }
    }
    case 'ADD_STEP_RUN_FILTERS': {
      return {
        ...state,
        stepRunFilters: [
          ...state.stepRunFilters,
          ...action.payload.filter(
            (f) => !state.stepRunFilters.some((s) => s.step_id === f.step_id)
          ),
        ],
      }
    }
    case 'REMOVE_STEP_RUN_FILTERS': {
      return {
        ...state,
        stepRunFilters: state.stepRunFilters.filter(
          (filter) => !action.payload.includes(filter.step_id)
        ),
      }
    }
    case 'UPDATE_STEP_RUN_FILTER': {
      return {
        ...state,
        stepRunFilters: state.stepRunFilters.map((filter) => {
          if (filter.step_id === action.payload.stepId) {
            return {
              ...filter,
              [action.payload.key]: action.payload.value,
            }
          }
          return filter
        }),
      }
    }
    case 'UPDATE_SCHEDULER_PAGE_CONFIG': {
      return {
        ...state,
        schedulerPageConfig: {
          ...state.schedulerPageConfig,
          ...action.payload,
        },
      }
    }
    case 'UPSERT_STEP_RUN_FILTER': {
      if (action.payload?.value)
        return {
          ...state,
          stepRunFilters: [
            ...state.stepRunFilters.filter((f) => f.step_id !== action.payload?.key),
            action.payload.value,
          ],
        }
      return {
        ...state,
        stepRunFilters: state.stepRunFilters.filter((f) => f.step_id !== action.payload?.key),
      }
    }

    default:
      return state
  }
}

export { stateReducer }
export type { TKeyValue, TReducerAction, TReducerState, TSchedulerConfig, TStepRunFilters }
