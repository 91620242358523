import { logger } from '@invisible/logger/client'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import axios from 'axios'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../../config/env'
import { TBaseRunVariables } from './types'

type TParams = {
  config: WizardSchemas.RLHFPlus.TSchema
  stepRunId: string | undefined
  baseRunVariables: TBaseRunVariables
}

export const fetchResponseSelectValidationFailures = async ({
  config,
  stepRunId,
  baseRunVariables,
}: TParams) => {
  try {
    if (config.responseSelectValidations && config.responseSelectValidations.length > 0) {
      const res = await axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/ait/validations/rlhf/response-select`,
        {
          step_run_id: stepRunId,
          base_run_variables: baseRunVariables,
          config,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (!res) {
        logger.error(`No response for RLHF Response Selection Validation`, {
          step_run_id: stepRunId,
          base_run_variables: baseRunVariables,
          config,
        })
        return ['Could not run validation for Response Selection']
      }

      if (res.data.validation_result === false) {
        return res.data.validation_failure_reasons
      }
    }
  } catch (error) {
    logger.error(`Error running RLHF Response Selection Validation`, {
      step_run_id: stepRunId,
      base_run_variables: baseRunVariables,
      config,
    })
  }

  return []
}
