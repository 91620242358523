import { random as randomize, sortBy } from 'lodash'

import { TAnnotation } from './types'

/**
 * Splits a given text into segments based on the provided offsets.
 * Each segment is either marked or unmarked based on whether it corresponds to an offset range.
 */
export const splitWithOffsets = (text: string, offsets: TAnnotation[]) => {
  let lastEnd = 0
  const splits = []

  for (const offset of sortBy(offsets, (o) => o.start)) {
    const { start, end } = offset
    if (lastEnd < start) {
      splits.push({
        start: lastEnd,
        end: start,
        text: text.slice(lastEnd, start),
      })
    }
    splits.push({
      ...offset,
      mark: true,
      text: text.slice(start, end),
    })
    lastEnd = end
  }
  if (lastEnd < text.length) {
    splits.push({
      start: lastEnd,
      end: text.length,
      text: text.slice(lastEnd, text.length),
    })
  }

  return splits
}

// Checks if a given text selection is empty, meaning the selection has no range.
export const selectionIsEmpty = (selection: Selection) => {
  const position = selection.anchorNode?.compareDocumentPosition(selection.focusNode as Node)

  return position === 0 && selection.focusOffset === selection.anchorOffset
}

// Determines if a text selection was made backwards, meaning from the end to the start of the selection.
export const selectionIsBackwards = (selection: Selection) => {
  if (selectionIsEmpty(selection)) return false

  const position = selection.anchorNode?.compareDocumentPosition(selection.focusNode as Node)

  let backward = false
  if (
    (!position && selection.anchorOffset > selection.focusOffset) ||
    position === Node.DOCUMENT_POSITION_PRECEDING
  )
    backward = true

  return backward
}

// Function to remove only the beginning and ending tag symbols around URLs
export const removeEnclosingTagsAroundUrls = (text: string): string =>
  text.replace(/<(https?:\/\/)?www\.[^<>]+>/g, (match) => match.replace(/<|>/g, ''))

// Function to decide Shortcut Keys
export const getShortcut = (text: string): string => {
  let shortcut = ''
  if (!text) return shortcut

  const storedShortcuts: Record<string, string> = getStoredShortcuts()

  // Return the existing shortcut if it's already stored for the given text
  if (storedShortcuts && storedShortcuts[text]) return storedShortcuts[text]

  const ignoreWords = ['and', '&', 'or']

  // Split the input text into words and filter out ignored words
  const words = text.split(/\s+/).filter((word) => !ignoreWords.includes(word.toLowerCase()))

  if (words.length === 1) {
    // Use the first & 2nd letters of the first single word
    shortcut = words[0][0]?.toUpperCase() + words[0][1]?.toUpperCase()
  } else if (words.length === 2) {
    // Use the first letters of both words
    shortcut = words[0][0]?.toUpperCase() + words[1][0]?.toUpperCase()
  } else if (words.length >= 3) {
    // Use the first letter of the first word and the first letter of the third word
    shortcut = words[0][0]?.toUpperCase() + words[2][0]?.toUpperCase()
  }

  // To Avoid Duplicates by Generating Random Letter
  const storedShortcutValues = Object.values(storedShortcuts)
  if (storedShortcutValues?.includes(shortcut)) {
    const alphaNumerics = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let uniqueFound = false

    // Loop to generate a unique shortcut by appending a random alphanumeric character.
    while (!uniqueFound) {
      shortcut = `${shortcut[0]}${alphaNumerics[randomize(0, alphaNumerics.length - 1)]}`

      // Stop if the random generated shortcut is not present in the stored ones
      if (!storedShortcutValues?.includes(shortcut)) {
        uniqueFound = true
      }
    }
  }

  storedShortcuts[text] = shortcut
  sessionStorage.setItem('shortcuts', JSON.stringify(storedShortcuts))
  return shortcut
}

// Get Entity Shortcuts from local Storage
export const getStoredShortcuts = () => JSON.parse(sessionStorage.getItem('shortcuts') || '{}')
