import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useRef, useState } from 'react'

import { DateMathOrDateRangeSwitcher } from './DateMathOrDateRangeSwitcher'
import { convertDateRangeToHumanReadableText } from './helpers'

const DEFAULT_X_DAYS = 10
export const DATE_PRESETS = {
  Today: ['now/d', 'now'],
  'Current Week': ['now/w', 'now'],
  'Current Month': ['now/M', 'now'],
  'Last 7 days': ['now-7d', 'now'],
  'Last calendar week': ['now/w-1w', 'now/w'],
  'Last 30 days': ['now-30d', 'now'],
  'Last calendar month': ['now/M-1M', 'now/M'],
  'Last calendar year': ['now/y-1y', 'now/y'],
  'Last X days': [`now-${DEFAULT_X_DAYS}d`, 'now'],
} as const
export const timeRangeOptions = [...Object.keys(DATE_PRESETS), 'Custom Value (Default)'] as const

// Matches now-Xd, where X is a number, excluding 7 and 30, and captures X as days
export const lastXDaysRegex = /now-(?!(7|30)d)(?<days>\d+)d/

type TValue = {
  start_date?: string
  end_date?: string
} | null

interface IProps {
  placeholder?: string
  label?: string
  onChange: (value: unknown) => void
  onClick?: () => void
  value: TValue
  reference: string
}

const DateFilter = ({ placeholder, onChange, value, label, onClick, reference }: IProps) => {
  const inputRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState<TValue>(value)

  return (
    <>
      <TextField
        size='small'
        placeholder={placeholder}
        label={value ? label : undefined}
        sx={{ cursor: 'pointer', width: '220px' }}
        inputRef={inputRef}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <CalendarTodayIcon sx={{ color: 'grey.600', fontSize: '16px', mr: '8px' }} />
          ),
          endAdornment: value ? (
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                onChange(null)
              }}>
              <CloseIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          ) : null,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen(true)
          }
        }}
        value={value ? convertDateRangeToHumanReadableText(value) : ''}
        onClick={() => {
          setIsOpen(true)
          onClick?.()
        }}
      />

      <Dialog
        open={isOpen}
        maxWidth='sm'
        fullWidth
        onClose={() => {
          setIsOpen(false)
          setTimeout(() => inputRef.current?.blur(), 0)
        }}>
        <DialogTitle>{`Choose a custom time-range for ${reference}`}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <Typography color='grey.600'>
            You can choose, below, a more precise range for this item within your current export.
          </Typography>

          <DateMathOrDateRangeSwitcher
            value={selectedValue}
            handleChange={(date) => setSelectedValue(date)}
          />
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ fontWeight: 'normal' }}
            onClick={() => {
              setSelectedValue(value)
              setIsOpen(false)
            }}>
            CANCEL
          </Button>
          <Button
            sx={{ fontWeight: 'normal' }}
            disabled={!selectedValue}
            onClick={() => {
              onChange(selectedValue)
              setIsOpen(false)
            }}>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { DateFilter }
