import type { TProcess, TProcessByIdStep, TProcessStepTemplate } from '@invisible/common/components/process-base'
import {
  mapGraphqlToUnifiedProcess,
  useProcessById,
} from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { useStore } from '@invisible/common/stores/process-store'
import { IProcessByIdQuery, toGlobalId } from '@invisible/concorde/gql-client'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { CaretLeftIcon, CaretRightIcon } from '@invisible/ui/icons'
import { AttendedMapStepMeta } from '@invisible/ultron/zod'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Handle, Position, useUpdateNodeInternals } from 'reactflow'
import { useGate } from 'statsig-react'
import shallow from 'zustand/shallow'

import { computeBuilderStepColors } from './helper'
import { ShadowStep } from './ShadowStep'

interface IProps {
  selected: boolean
  data: {
    step: TProcessByIdStep
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const AttendedMapStep: React.FC<IProps> = ({ selected, data }) => {
  const { value: isGraphqlEnabled } = useGate('enable-graphql-process-by-id-query')
  const graphqlQueryClient = useQueryClient()
  const updateNodeInternals = useUpdateNodeInternals()
  const reactQueryContext = useContext()
  const { layoutOrientation } = useStore(
    useCallback(
      (state) => ({
        layoutOrientation: state.layoutOrientation,
      }),
      []
    ),
    shallow
  )

  const [shadowStepActive, setShadowStepActive] = useState(false)

  const { data: process } = useProcessById({ id: data.step.processId })

  const step = useMemo(
    () => process?.steps.find((s) => s.id === data.step.id),
    [data.step.id, process?.steps]
  )
  const meta = step?.meta as AttendedMapStepMeta.TSchema
  const colors = computeBuilderStepColors(step?.stepTemplate as TProcessStepTemplate)

  const queryKey = isGraphqlEnabled
    ? ['ProcessById', { id: toGlobalId('ProcessType', data.step.processId) }]
    : ['process.findByIdWithStepsAndStepGoTos', { id: data.step.processId }]

  const queryClient = isGraphqlEnabled ? graphqlQueryClient : reactQueryContext.queryClient

  const { mutateAsync: createStepGoTo } = useMutation('stepGoTo.create', {
    onSettled: () => {
      graphqlQueryClient.invalidateQueries('ProcessById')
      reactQueryContext.invalidateQueries('process.findByIdWithStepsAndStepGoTos')
    },
    onMutate: async (variables) => {
      await graphqlQueryClient.cancelQueries('ProcessById')
      await reactQueryContext.queryClient.cancelQueries('process.findByIdWithStepsAndStepGoTos')
      queryClient.setQueryData(
        queryKey,
        // @ts-expect-error Wrong types
        (prevData: TProcess | IProcessByIdQuery) => {
          if (!prevData) return
          const mappedPrevData =
            isGraphqlEnabled && 'process' in prevData
              ? (mapGraphqlToUnifiedProcess(prevData) as TProcess)
              : (prevData as TProcess)
          return {
          ...mappedPrevData,
          stepGoTos: [...mappedPrevData.stepGoTos, { ...variables }],
        }}
      )
    },
  })

  const connectNodes = async (fromStepId: string | null, toStepId: string | null) => {
    if (!fromStepId || !toStepId || process?.status === 'active' || process?.status === 'testing')
      return

    const fromStep = process?.steps.find((s) => s.id === fromStepId)
    const toStep = process?.steps.find((s) => s.id === toStepId)

    if (
      (toStep?.stepTemplate?.subtype === 'audit' &&
        !['end_base_run', 'end_map', 'end_process', 'end_attended_map'].includes(
          fromStep?.stepTemplate?.subtype ?? ''
        )) ||
      (fromStep?.stepTemplate?.subtype === 'audit' &&
        !['end_base_run', 'end_map', 'end_process', 'end_attended_map'].includes(
          toStep?.stepTemplate?.subtype ?? ''
        ))
    )
      return

    await createStepGoTo({
      goFromStepId: fromStepId,
      goToStepId: toStepId,
      processId: data.step.processId,
    })
  }

  useEffect(() => {
    updateNodeInternals(data.step.id)
  }, [layoutOrientation])

  if (!process) return null

  return (
    <div className='relative flex h-16'>
      <div
        className={classNames(
          'text-primary relative z-10 box-border flex h-16 cursor-default items-center gap-2 rounded border-2 border-solid bg-white px-2',
          selected ? 'border-black' : colors.border
        )}>
        <div className='bg-fuchsia-main border-fuchsia flex h-8 w-8 shrink-0 items-center justify-center rounded-md'>
          <colors.icon className='text-void h-4 w-4' />
        </div>
        <div className='flex w-40 flex-col text-xs '>
          <span className='block truncate font-bold tracking-wider'>{step?.name ?? ''}</span>
          <span className='block truncate tracking-wide'>{data.step.stepTemplate.name}</span>{' '}
        </div>
        <Handle
          type='target'
          isConnectable={process.status !== 'active' && process.status !== 'testing'}
          position={layoutOrientation === 'horizontal' ? Position.Left : Position.Top}
          className={classNames('!h-2 !w-2', colors.bg)}
        />
        <Handle
          type='source'
          position={layoutOrientation === 'horizontal' ? Position.Right : Position.Bottom}
          className={classNames('!h-2 !w-2', colors.bg)}
          onConnect={(connection) => connectNodes(connection.source, connection.target)}
        />
      </div>

      {!shadowStepActive ? (
        <div
          className='nodrag bg-void ml-1 flex h-full w-6 cursor-pointer items-center justify-center rounded'
          onClick={() => setShadowStepActive(true)}>
          <CaretRightIcon width={12} height={12} className='text-muted' />
        </div>
      ) : null}

      {shadowStepActive ? (
        <>
          <ShadowStep
            stepId={meta?.shadowStepId}
            attendedMapStepId={data.step.id}
            processId={data.step.processId}
          />
          <div
            className='nodrag ml-1 flex h-full w-6 cursor-pointer items-center justify-center rounded bg-white'
            onClick={() => setShadowStepActive(false)}>
            <CaretLeftIcon width={12} height={12} className='text-gray-500' />
          </div>
        </>
      ) : null}
    </div>
  )
}

export { AttendedMapStep }
