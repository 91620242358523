import {
  fromGlobalId,
  IStepTemplatesQuery,
  useStepTemplatesQuery,
} from '@invisible/concorde/gql-client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { useEffect, useState } from 'react'
import { useGate } from 'statsig-react'
import { JsonValue } from 'type-fest'

export interface UnifiedStepTemplate {
  id: string
  name: string
  description?: string | null
  type: string
  subtype: string
  source: string
  meta?: JsonValue
  stepTemplateCategoryId?: string
  companyId?: string
  serviceId?: string
  icon?: JsonValue
}

const mapTrpcToUnified = (
  data: NonNullable<inferQueryOutput<'stepTemplate.getStepTemplatesInfo'>>
): UnifiedStepTemplate[] =>
  data.map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description ?? '',
    icon: item.icon,
    type: item.type,
    subtype: item.subtype ?? '',
    source: item.source,
    stepTemplateCategoryId: item.stepTemplateCategoryId ?? '',
    companyId: item.companyId ?? '',
    serviceId: item.serviceId ?? '',
  }))

const mapGraphqlToUnified = (data: IStepTemplatesQuery): UnifiedStepTemplate[] =>
  data.stepTemplates.edges.map((edge) => {
    const node = edge.node
    return {
      id: fromGlobalId(node.id),
      name: node.name,
      description: node.description ?? '',
      icon: node.icon,
      type: node.type,
      subtype: node.subtype ?? '',
      source: node.source,
      meta: node.meta,
      stepTemplateCategoryId: fromGlobalId(node.stepTemplateCategory?.id) ?? '',
      companyId: fromGlobalId(node.company?.id) ?? '',
      serviceId: fromGlobalId(node.service?.id) ?? '',
    }
  })

interface UseStepTemplatesProps {
  enabled?: boolean
}

const useStepTemplates = ({ enabled = true }: UseStepTemplatesProps = {}) => {
  const { value: isGraphqlEnabled } = useGate('enable-graphql-steptemplateinfo-query')

  // State to accumulate GraphQL results
  const [allGraphqlResults, setAllGraphqlResults] = useState<UnifiedStepTemplate[]>([])
  const [endCursor, setEndCursor] = useState('')
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  // Disable queries if isGraphqlEnabled is true
  const shouldRunTrpcQuery = !isGraphqlEnabled && enabled
  const shouldRunGraphqlQuery = isGraphqlEnabled && enabled

  // tRPC query
  const { data: trpcResult, isLoading: trpcLoading } = useTrpcQuery(
    ['stepTemplate.getStepTemplatesInfo'],
    {
      enabled: shouldRunTrpcQuery, // This disables tRPC when GraphQL is enabled
    }
  )

  // GraphQL query
  const { data: graphqlResult, isLoading: graphqlLoading } = useStepTemplatesQuery(
    {
      ...(endCursor ? { after: endCursor } : {}),
    },
    {
      enabled: shouldRunGraphqlQuery, // This disables GraphQL when tRPC is enabled
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 8,
      keepPreviousData: true,
    }
  )

  // Accumulate and dedupe fetched data into local state
  useEffect(() => {
    if (!graphqlResult || !isGraphqlEnabled || !enabled) return

    const { stepTemplates } = graphqlResult
    const { totalCount, pageInfo } = stepTemplates
    const { hasNextPage = false, endCursor = '' } = pageInfo

    if (allGraphqlResults.length === totalCount) return

    const newResults = mapGraphqlToUnified(graphqlResult)
    setAllGraphqlResults((prev) => {
      const newStepTemplates = newResults.filter((s) => !prev.some((p) => p.id === s.id))
      const updatedResults = [...prev, ...newStepTemplates]
      return updatedResults
    })

    setIsFetchingMore(hasNextPage)
    setEndCursor(endCursor as string)
  }, [graphqlResult, isGraphqlEnabled, enabled])

  // Conditionally return data based on which query is enabled
  const data = !enabled
    ? null
    : isGraphqlEnabled
    ? allGraphqlResults
    : mapTrpcToUnified(trpcResult ?? [])

  // Loading state depends on the query that's being run
  const isLoading = isGraphqlEnabled ? graphqlLoading || isFetchingMore : trpcLoading

  return {
    data,
    isLoading,
  }
}

export { useStepTemplates }
