import {
  IBaseFilterConfigsQuery,
  IExportConfigurationDataQuery,
} from '@invisible/concorde/gql-client'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { Dispatch, SetStateAction } from 'react'
import { Control, FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form'

import { ExportDetails } from './ExportDetails'
import { ExportTaskScheduleConfiguration } from './ExportTaskScheduleConfiguration'
import { FilterConfiguration } from './FilterConfiguration'
import { Preview } from './Preview'
import { Recipients } from './Recipients'
import { TReducerAction, TReducerState } from './reducer'
import { TFormSchema } from './schema'

const getStepColor = ({
  step,
  activeStep,
  highestStepReached,
}: {
  step: number
  activeStep: number
  highestStepReached: number
}) => {
  if (step === activeStep) return 'primary'
  if (step <= highestStepReached) return 'primary.200'
  return 'grey.500'
}

const TAB_NAMES = ['General', 'What', 'When & how often', 'Preview', 'Recipients']

interface IProps {
  state: TReducerState
  handleTabChange: (tab: number) => void
  dispatch: Dispatch<TReducerAction>
  hasScheduleBeenEdited: boolean
  setIsScheduleConfigReset: (value: boolean) => void
  setCurrentScheduleDate: Dispatch<SetStateAction<{ schedule: string; date: Date }>>
  processData: IExportConfigurationDataQuery | undefined
  filterConfigs: IBaseFilterConfigsQuery['baseFilterConfigs']['edges']
  reactHookForm: {
    register: UseFormRegister<TFormSchema>
    control: Control<TFormSchema, object>
    errors: FieldErrors<TFormSchema>
    setValue: UseFormSetValue<TFormSchema>
  }
}

const CreateExportDialogStepper = ({
  state,
  reactHookForm: { register, errors, control, setValue },
  handleTabChange,
  dispatch,
  hasScheduleBeenEdited,
  setIsScheduleConfigReset,
  setCurrentScheduleDate,
  processData,
  filterConfigs,
}: IProps) => {
  const TAB_COMPONENTS: Record<number, JSX.Element> = {
    1: (
      <ExportDetails
        state={state}
        bases={processData?.process.bases ?? []}
        register={register}
        errors={errors}
        control={control}
      />
    ),
    2: (
      <FilterConfiguration
        state={state}
        dispatch={dispatch}
        processData={processData}
        filterConfigs={filterConfigs}
      />
    ),
    3: (
      <ExportTaskScheduleConfiguration
        state={state}
        dispatch={dispatch}
        onEdit={hasScheduleBeenEdited}
        setIsReset={setIsScheduleConfigReset}
        setCurrentScheduleDate={setCurrentScheduleDate}
      />
    ),
    4: <Preview state={state} dispatch={dispatch} errors={errors} control={control} />,
    5: <Recipients control={control} setValue={setValue} errors={errors} />,
  }

  return (
    <>
      <Box py='20px' top={0} position='sticky' zIndex={10} bgcolor='white'>
        <Stepper nonLinear activeStep={state.formTab - 1}>
          {TAB_NAMES.map((label, index) => (
            <Step
              key={label}
              // Show completed check if the tab is before the current tab
              completed={index + 1 < state.formTab}>
              <StepButton
                sx={{
                  '& .MuiStepIcon-root': {
                    color: getStepColor({
                      step: index + 1,
                      activeStep: state.formTab,
                      highestStepReached: state.highestFormTabReached,
                    }),
                  },
                }}
                onClick={() => {
                  // Do nothing if the user clicks on the current tab
                  if (index + 1 === state.formTab) return
                  // Do nothing if the user clicks on a tab that has not been visited and the user is not on the preceding tab
                  if (index + 1 > state.highestFormTabReached && index !== state.formTab) return

                  handleTabChange(index + 1)
                }}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box component='form' noValidate px='12px'>
        {TAB_COMPONENTS[state.formTab]}
      </Box>
    </>
  )
}
export { CreateExportDialogStepper }
