import { useProcessById } from '@invisible/common/components/process-base'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import {
  fromGlobalId,
  IAgentScoreType,
  toGlobalId,
  useAgentScoresQuery,
  useBaseRunVariablesQuery,
} from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import { theme } from '@invisible/ui/mui-theme-v2'
import { QaStepMeta, Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { isNil } from 'lodash'
import { isEmpty } from 'radash'
import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { StepRunScore } from './components/StepRunScore'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TStep = TStepRun['step']

type IProps = WizardSchemas.WACConfig.TSchema & {
  stepRun: TStepRun & {
    step: TStep & {
      stepVariableReferences?: { baseVariableId: string; stepTemplateVariable: { key: string } }[]
    }
  }
  baseRun: TBaseRun
  isReadOnly: boolean
}

type TQAForm = {
  madeCorrections: boolean
  qaNotes: string
  sendTo: string
}

const QaWAC = ({ stepRun, baseRun, isReadOnly }: IProps) => {
  const [formValues, setFormValues] = useState<TQAForm>({
    madeCorrections: false,
    qaNotes: '',
    sendTo: '',
  })
  const [shouldContinue, setShouldContinue] = useState<'yes' | 'no'>()
  const reactQueryClient = useQueryClient()
  const { dispatch, state } = useWizardState()

  const { mutateAsync: updateBaseRunVariables, isLoading: isUpdatingBaseRunVariables } =
    useBaseRunVariablesWizardUpdate({
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
        reactQueryClient.invalidateQueries([
          'BaseRunVariables',
          {
            filters: {
              baseRunId: toGlobalId('BaseRun', baseRun.id),
              baseVariableIds: (stepRun?.step?.stepVariableReferences ?? []).map((stvr) =>
                toGlobalId('BaseVariable', stvr.baseVariableId)
              ),
            },
          },
        ])
      },
    })

  const { data: stepsToQa, isLoading: loadingStepsToQa } = useQuery(
    [
      'stepRun.findManyStepRunsByQaStepRunIdToScore',
      {
        baseRunId: baseRun.id,
        qaStepRunId: stepRun?.id ?? '',
        stepIds: (stepRun?.step?.meta as QaStepMeta.TSchema).config.stepsToQa,
      },
    ],
    {
      enabled: !!(stepRun?.step?.meta as QaStepMeta.TSchema).config.stepsToQa,
    }
  )

  const { data: process } = useProcessById({
    id: state.processId ?? '',
    enabled: !!state.processId,
  })

  const { data: qaBaseRunVariables, isLoading: loadingQaBaseRunVariables } =
    useBaseRunVariablesQuery(
      {
        filters: {
          baseRunId: toGlobalId('BaseRun', baseRun.id),
          baseVariableIds: (stepRun?.step?.stepVariableReferences ?? []).map((stvr) =>
            toGlobalId('BaseVariable', stvr.baseVariableId)
          ),
        },
      },
      {
        enabled: !isEmpty(stepRun?.step?.stepVariableReferences) && !!baseRun,
      }
    )

  const { data: agentScoresData, isLoading } = useAgentScoresQuery({
    qaStepRunId: toGlobalId('StepRun', stepRun?.id),
  })

  const canSubmitQaResults = useMemo(
    () =>
      stepsToQa?.every((step) =>
        agentScoresData?.agentScores?.edges.some(
          (edge) => fromGlobalId(edge?.node?.stepRun?.id ?? '') === step.id
        )
      ) && !isEmpty(formValues),
    [stepsToQa, agentScoresData, formValues]
  )

  const readyToSubmit = useMemo(
    () =>
      shouldContinue &&
      (shouldContinue === 'no'
        ? formValues?.['sendTo']
        : agentScoresData?.agentScores?.edges.some((edge) => edge?.node?.score === 0)
        ? formValues?.['madeCorrections']
        : true),
    [shouldContinue, formValues, agentScoresData]
  )

  useEffect(() => {
    setFormValues((prevFormValues) =>
      Object.keys(prevFormValues).reduce(
        (acc, key) => ({
          ...acc,
          [key]:
            qaBaseRunVariables?.baseRunVariables?.edges?.find(
              (variable) =>
                fromGlobalId(variable.node.baseVariable.id) ===
                (stepRun?.step?.stepVariableReferences ?? []).find(
                  (svr) => svr.stepTemplateVariable?.key === key
                )?.baseVariableId
            )?.node.value ?? (key === 'madeCorrections' ? false : ''),
        }),
        prevFormValues
      )
    )
    if (
      qaBaseRunVariables?.baseRunVariables?.edges?.find(
        (variable) =>
          fromGlobalId(variable.node.baseVariable.id) ===
            (stepRun?.step?.stepVariableReferences ?? []).find(
              (svr) => svr.stepTemplateVariable?.key === 'sendTo'
            )?.baseVariableId ?? ''
      )?.node.value
    ) {
      setShouldContinue('no')
    } else {
      setShouldContinue('yes')
    }
    dispatch({
      type: 'setReadyForSubmit',
      key: 'qa',
      value: (qaBaseRunVariables?.baseRunVariables?.edges ?? []).some(
        (variable) => !isNil(variable.node.value)
      ),
    })
  }, [qaBaseRunVariables])

  const handleSubmitQaResults = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('submitting QA results', formValues)
    const variablesToSubmit = Object.keys(formValues ?? {}).map((key) => ({
      baseRunId: baseRun.id,
      baseVariableId:
        (stepRun?.step?.stepVariableReferences ?? []).find(
          (svr) => svr.stepTemplateVariable?.key === key
        )?.baseVariableId ?? '',
      value: formValues?.[key as keyof typeof formValues] ?? '',
    }))

    if (readyToSubmit) {
      await updateBaseRunVariables({ stepRunId: stepRun.id, data: variablesToSubmit })
    }
    dispatch({
      type: 'setReadyForSubmit',
      key: 'qa',
      value: !!readyToSubmit,
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <CardContent>
          <Stack position='relative' width='100%' gap='8px'>
            <Typography fontWeight='bold' fontSize='16px'>
              {(stepsToQa ?? []).length > 1
                ? `Score the following tasks from this case (${stepsToQa?.length ?? 0})`
                : 'Score the following task'}
            </Typography>
            {isLoading || loadingQaBaseRunVariables || loadingStepsToQa ? <LinearProgress /> : null}
            {(stepsToQa ?? []).map((stepToQa) => (
              <StepRunScore
                expandAccordion={(stepsToQa ?? []).length === 1}
                key={stepToQa.id}
                stepRun={stepToQa}
                qaStepRunId={stepRun?.id ?? ''}
                submittedQaResult={
                  agentScoresData?.agentScores?.edges?.find(
                    (edge) => fromGlobalId(edge?.node?.stepRun?.id ?? '') === stepToQa.id
                  )?.node as Pick<IAgentScoreType, 'id' | 'notes' | 'score'>
                }
                wizardIsReadOnly={isReadOnly}
              />
            ))}
            <form onSubmit={handleSubmitQaResults}>
              <Stack direction='column' gap='16px'>
                <Typography fontWeight='bold' color='text.secondary' fontSize='16px'>
                  Score all tasks to move on...
                </Typography>
                <ButtonGroup
                  variant='outlined'
                  fullWidth
                  aria-label='outlined primary button group'>
                  <Button
                    variant={shouldContinue === 'no' ? 'contained' : 'outlined'}
                    disabled={!canSubmitQaResults || isReadOnly}
                    onClick={() => setShouldContinue('no')}>
                    Send case to
                  </Button>
                  <Button
                    variant={shouldContinue === 'yes' ? 'contained' : 'outlined'}
                    disabled={!canSubmitQaResults || isReadOnly}
                    onClick={() => {
                      setShouldContinue('yes')
                      setFormValues((prev) => ({
                        ...prev,
                        sendTo: '',
                      }))
                    }}>
                    Continue
                  </Button>
                </ButtonGroup>
                {shouldContinue === 'no' ? (
                  <FormControl>
                    <InputLabel sx={{ lineHeight: '12px' }}>
                      Select the step to return this case to *
                    </InputLabel>
                    <Select
                      size='small'
                      defaultValue=''
                      disabled={!canSubmitQaResults || isReadOnly}
                      fullWidth
                      value={formValues?.['sendTo']}
                      error={formValues?.['sendTo'] === ''}
                      onChange={(event: SelectChangeEvent) => {
                        setFormValues((prev) => ({
                          ...prev,
                          sendTo: event.target.value,
                        }))
                      }}
                      label='Select the step to return this case to *'>
                      <MenuItem disabled value=''>
                        Select
                      </MenuItem>
                      {(process?.steps ?? [])
                        .filter(
                          (step) =>
                            step.baseId === stepRun?.step?.baseId &&
                            step.stepTemplate?.type === 'manual' &&
                            !step.stepTemplate?.subtype
                        )
                        .map((step) => (
                          <MenuItem key={step.id} value={step.id}>
                            {step.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : null}
                <RadioGroup
                  row
                  onChange={(event) => {
                    setFormValues((prev) => ({
                      ...prev,
                      madeCorrections: event.target.value === 'yes',
                    }))
                  }}
                  value={formValues?.madeCorrections ? 'yes' : 'no'}
                  name='row-radio-buttons-group'>
                  <FormControlLabel
                    value='yes'
                    disabled={!canSubmitQaResults || isReadOnly}
                    control={<Radio />}
                    label='Made corrections'
                  />
                  <FormControlLabel
                    value='no'
                    disabled={!canSubmitQaResults || isReadOnly}
                    control={<Radio />}
                    label='No corrections'
                  />
                </RadioGroup>
                <TextField
                  id='outlined-multiline-static'
                  label='Provide rework/QA feedback'
                  multiline
                  rows={4}
                  onChange={(event) =>
                    setFormValues((prev) => ({
                      ...prev,
                      qaNotes: event.target.value,
                    }))
                  }
                  value={formValues?.qaNotes ?? ''}
                  defaultValue='Provide rework/QA feedback'
                  disabled={!canSubmitQaResults || isReadOnly}
                />

                <LoadingButton
                  disabled={
                    !canSubmitQaResults ||
                    !readyToSubmit ||
                    isUpdatingBaseRunVariables ||
                    isReadOnly
                  }
                  loading={isUpdatingBaseRunVariables}
                  variant='contained'
                  type='submit'
                  sx={{ alignSelf: 'end' }}>
                  Submit QA Result
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </CardContent>
      </Card>
    </ThemeProvider>
  )
}

export { QaWAC }
